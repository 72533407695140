<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="top">
            <p>当前您是:当前用户</p>
        </div>
        <div class="middle">
            <div class="middle-item" @click="goPage('masterPay')">
                <div class="itemtext">
                    <p>包月用户<span>包月</span></p>
                    <p>包月后，30天内免费看所有达人，大师预测心水、图规、图书、不限次数</p>
                </div>
                <div class="itemimg">
                    <img src="../../assets/images/right_arrow.png" alt="">
                </div>
            </div>
            <div class="middle-item">
                <div class="line"></div>
                <div class="itemtext">
                    <p>普通用户</p>
                    <p>需支付太阳，才能看达人、大师预测心水、图规、图书</p>
                </div>
                <!-- <div class="itemimg">
                    <img src="../../assets/images/right_arrow.png" alt="">
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted(){
        
     //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
        
    },
}
</script>
<style lang="less" scoped>
    .container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: #eee;
        .top{
            p{
                font-size: 12px;
                color: rgb(203, 63, 63);
                padding-left: 10px;
            }
        }
        .middle{
            .middle-item{
                width: 100%;
                height: 100px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 10px;
                position: relative;
                border-bottom: 1px solid #eee;
                &:first-of-type:active{
                    background-color: #ddd;
                }
                .itemtext{
                    width: 88%;
                    padding-left:10px;
                    p{
                        width: 98%;
                        font-size: 14px;
                        &:last-of-type{
                            color: rgb(134, 132, 132);
                        }
                        &:first-of-type{

                            span{
                                vertical-align: top;

                                display: inline-block;
                                font-size: 12px;
                                width: 40px;
                                height: 20px;
                                background-color: rgb(237, 196, 50);
                                text-align: center;
                                border-radius: 6px;
                                line-height: 20px;
                                /* padding-top: 1px; */
                                margin-left: 4px;
                            }
                        }
                    }
                }
                .itemimg{
                    img{
                        width: 20px;
                        height: 20px;
                    }
                }
                .line{
                    width: 6px;
                    height: 100%;
                    background-color: rgb(190, 60, 60);
                    left:-2px;
                    position: absolute;
                }
            }
        }
    }
</style>